import type { Query, Dobby } from "@moirei/dobby";
import { get as _get, remove as _remove } from "lodash";

export const useListQuery = <T extends Dobby>(
  query: Query<any>,
  lazy?: boolean
) => {
  const refetching = ref(false);
  const fetching = ref(false);
  const items = shallowRef<T[]>([]);

  const loading = computed(() => fetching.value || refetching.value);
  const isEmpty = computed(() => !items.value.length);

  const events = useNamespacedEvent(uuid());

  const fetch_ = async () => {
    items.value = (await query.findMany()).map((item) => markRaw(item));
    events.dispatch("result", items.value);
  };

  const fetch = async () => {
    fetching.value = true;
    await fetch_();
    fetching.value = false;
  };

  const refetch = async () => {
    refetching.value = true;
    await fetch_();
    refetching.value = false;
  };

  if (!lazy) {
    onMounted(fetch);
  }

  const onResult = (listener: { (result: T[]): any }) => {
    events.handle("result", listener);
  };

  return {
    fetching,
    refetching,
    loading,
    items,
    isEmpty,
    fetch,
    refetch,
    onResult,
  };
};
